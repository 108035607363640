<template>
    <v-card
            class="pa-6"
            elevation="24"
    >
        <v-skeleton-loader
                v-if="loadingTask"
                type="paragraph, paragraph, paragraph, paragraph"
        ></v-skeleton-loader>
        <v-form
                v-else
                @submit.prevent="submit"
                ref="form"
                v-model.lazy="valid"
        >
            <v-text-field
                    clearable
                    label="Employée"
                    v-model="employee"
                    :rules="[rules.ruleRequired]"
                    autofocus
            ></v-text-field>
            <v-btn
                    color="success"
                    class="mt-4"
                    block
                    type="submit"
                    :loading="loading"
            >
                {{ _id ? "Editer" : "Ajouter" }}
            </v-btn>
            <ConfirmComponent
                    v-if="_id"
                    :item="current_employee"
                    :label="current_employee?.labelName"
                    :function_action="delete_employee"
            ></ConfirmComponent>
        </v-form>
    </v-card>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import ConfirmComponent from "@/components/ConfirmComponent.vue";

export default {
    name: "EmployeeComponent.vue",
    components: {ConfirmComponent},
    props: {
        _id: String
    },
    data: () => ({
        valid: false,
        employee: "",
        loading: false,
        loadingTask: false,
        rules: {
            ruleRequired: v => !!v || 'Le champ est requis'
        }
    }),
    computed: {
        ...mapState("dataStore", ["current_employee"])
    },
    methods: {
        ...mapMutations("dataStore", ["set_current_employee"]),
        ...mapActions("employeeStore", ["add_employee", "edit_employee", "get_employee_by_id", "delete_employee"]),
        async submit() {
            if ((await this.$refs.form.validate()).valid) {
                this.loading = true
                let employee = {
                    labelName: this.employee
                }
                if (this._id) {
                    employee._id = this._id
                    await this.edit_employee(employee)
                } else {
                    await this.add_employee(employee)
                }
            }
            this.loading = false
        }

    },
    async created() {
        await this.set_current_employee(null);
        if (this._id) {
            await this.get_employee_by_id(this._id)
            this.employee = this.current_employee?.labelName
        }
    },
    watch: {
        _id() {
            this.valid = false;
            this.employee = "";
            this.loading = false;
            this.loadingTask = false;
        }
    }
}
</script>

<style scoped>

</style>