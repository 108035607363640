import {createStore} from 'vuex'
import employeeStore from "@/store/employee.store";
import priorityStore from "@/store/priority.store";
import statusStore from "@/store/status.store";
import taskStore from "@/store/task.store";
import dataStore from "@/store/data.store";
import errorStore from "@/store/error.store";

export default createStore({
    modules: {
        employeeStore,
        priorityStore,
        statusStore,
        taskStore,
        dataStore,
        errorStore
    }
})