import axios from "axios";

const {baseURL} = require("../../.env.js")

const myAxios = axios.create({
    baseURL: baseURL
})


myAxios.interceptors.request.use(
    config => {
        return {...config, headers: {}}
    },
    error => {
        return Promise.reject(error)
    }
)

myAxios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.message === "Network Error") {
            return Promise.reject("Erreur de connexion avec l'API")
        }
        console.error("ERROR", error?.message);
        return Promise.reject(error)
    }
)


export default myAxios