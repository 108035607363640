export default {
    namespaced: true,
    state: {
        show: true,
        all_employee: [],
        current_employee: null,
        all_priority: [],
        current_priority: null,
        all_status: [],
        current_status: null,
        all_task: [],
        current_task: null
    },
    getters: {},
    mutations: {
        set_show: (state, show) => {
            state.show = show
        },
        set_all_employee: (state, all_employee) => {
            state.all_employee = all_employee;
        },
        set_current_employee: (state, current_employee) => {
            state.current_employee = current_employee
        },
        set_all_priority: (state, all_priority) => {
            state.all_priority = all_priority;
        },
        set_current_priority: (state, current_priority) => {
            state.current_priority = current_priority
        },
        set_all_status: (state, all_status) => {
            state.all_status = all_status;
        },
        set_current_status: (state, current_status) => {
            state.current_status = current_status
        },
        set_all_task: (state, all_task) => {
            state.all_task = all_task;
        },
        set_current_task: (state, current_task) => {
            state.current_task = current_task
        }
    },
    actions: {}
}