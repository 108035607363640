import employeeService from "@/services/employee.service";
import router from "@/router";


export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async get_all_employee({commit}) {
            try {
                const res = await employeeService.get_all_employee()
                commit("dataStore/set_all_employee", res.data.data, {root: true})
            } catch (e) {
                commit("errorStore/setError", e, {root: true})
            }
        },
        async get_employee_by_id({commit}, _id) {
            try {
                const res = await employeeService.get_employee_by_id(_id)
                if (res.data.data === null) {
                    throw new Error("Cette employée n'existe pas");
                }
                commit("dataStore/set_current_employee", res.data.data, {root: true})
            } catch (e) {
                await commit("errorStore/setError", e, {root: true})
                await router.push("/")
            }
        },
        async add_employee({commit}, employee) {
            try {
                await employeeService.add_employee(employee)
                await router.push("/")
            } catch (e) {
                commit("errorStore/setError", e, {root: true})
            }
        },
        async edit_employee({commit}, employee) {
            try {
                await employeeService.edit_employee(employee)
                await router.push("/")
            } catch (e) {
                commit("errorStore/setError", e, {root: true})
            }
        },
        async delete_employee({commit}, employee) {
            try {
                await employeeService.delete_employee(employee)
                await router.push("/")
            } catch (e) {
                commit("errorStore/setError", e, {root: true})
            }
        }
    }
}