<template>
        <v-dialog
            :model-value="isError"
            :min-width="500"
            width="auto"
            :retain-focus="false"
            :persistent="true"
            transition="dialog-top-transition"
            @click="closeMenu"
            class="not-select"
        >
            <v-card
                class="border-red"
                title="Erreur"
                :text="textError"
            >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
export default {
    name: 'ErrorComponent',
    data: () => ({
      intervalID: 0,
    }),
    computed: {
        ...mapState('errorStore', ['isError', 'textError']),
    },
    methods: {
        ...mapMutations("errorStore", ["popError"]),
        closeMenu() {
            this.popError();
        }
    },
    watch: {
      isError(oldVlue, newValue) {
        clearInterval(this.intervalID);
        this.intervalID = 0;
        if (!newValue) {
          this.intervalID = setTimeout(this.closeMenu, 1000 * 5)
        }
      }
    }
};
</script>

<style scoped>
.border-red {
    outline: 3px solid #D50000;
}
.not-select {
    user-select: none;
}
</style>