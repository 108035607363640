<template>
    <v-card class="pa-6"
            elevation="24">
        <v-skeleton-loader
                v-if="loadingTask"
                type="paragraph, paragraph, paragraph, paragraph"
        ></v-skeleton-loader>
        <v-form
                v-else
                @submit.prevent="submit"
                ref="form"
                v-model.lazy="valid"
        >
            <v-text-field
                    clearable
                    label="Status"
                    v-model="status"
                    :rules="[rules.ruleRequired]"
                    autofocus
            ></v-text-field>

            <v-text-field
                    label="Couleur"
                    v-model="color"
                    type="color"
                    :rules="[rules.ruleRequired]"
                    readonly
            ></v-text-field>
            <div class="d-flex justify-center">
                <v-color-picker
                        v-for="(i, index) in [0, 1, 2]"
                        :key="index"
                        class="ma-2 color-picker"
                        show-swatches
                        :width="53*tabColors.at(i).length+16"
                        hide-canvas
                        hide-inputs
                        hide-sliders
                        v-model="color"
                        :swatches="tabColors.at(i)"
                ></v-color-picker>
            </div>

            <v-btn
                    color="success"
                    class="mt-4"
                    block
                    type="submit"
                    :loading="loading"
            >
                {{ _id ? "Editer" : "Ajouter" }}
            </v-btn>
            <ConfirmComponent
                    v-if="_id"
                    :item="current_status"
                    :label="current_status?.labelStatus"
                    :function_action="delete_status"
            ></ConfirmComponent>
        </v-form>
    </v-card>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import ConfirmComponent from "@/components/ConfirmComponent.vue";

export default {
    name: "StatusComponent.vue",
    components: {ConfirmComponent},
    props: {
        _id: String
    },
    data: () => ({
        valid: false,
        status: "",
        color: "#000000",
        loading: false,
        loadingTask: false,
        rules: {
            ruleRequired: v => !!v || 'Le champ est requis'
        },
        tabColors: [
            [
                ['#B71C1C', '#C62828', '#D32F2F', '#E53935'],
                ['#880E4F', '#AD1457', '#C2185B', '#D81B60'],
                ['#4A148C', '#6A1B9A', '#7B1FA2', '#8E24AA'],
                ['#311B92', '#4527A0', '#512DA8', '#5E35B1'],
                ['#1A237E', '#283593', '#303F9F', '#3949AB'],
                ['#0D47A1', '#1565C0', '#1976D2', '#1E88E5'],
            ],
            [

                ['#01579B', '#0277BD', '#0288D1', '#039BE5'],
                ['#006064', '#00838F', '#0097A7', '#00ACC1'],
                ['#004D40', '#00695C', '#00796B', '#00897B'],
                ['#1B5E20', '#2E7D32', '#388E3C', '#43A047'],
                ['#33691E', '#558B2F', '#689F38', '#7CB342'],
                ['#827717', '#9E9D24', '#AFB42B', '#C0CA33'],
                ['#F57F17', '#F9A825', '#FBC02D', '#FDD835'],
            ],
            [
                ['#FF6F00', '#FF8F00', '#FFA000', '#FFB300'],
                ['#E65100', '#EF6C00', '#F57C00', '#FB8C00'],
                ['#BF360C', '#D84315', '#E64A19', '#F4511E'],
                ['#3E2723', '#4E342E', '#5D4037', '#6D4C41'],
                ['#263238', '#37474F', '#455A64', '#546E7A'],
                ['#212121', '#424242', '#616161', '#757575'],
            ],
        ]
    }),
    computed: {
        ...mapState("dataStore", ["current_status"])
    },
    methods: {
        ...mapMutations("dataStore", ["set_current_status"]),
        ...mapActions("statusStore", ["add_status", "edit_status", "get_status_by_id", "delete_status"]),
        async submit() {
            if ((await this.$refs.form.validate()).valid) {
                this.loading = true
                let status = {
                    labelStatus: this.status,
                    color: this.color
                }
                if (this._id) {
                    status._id = this._id
                    await this.edit_status(status)
                } else {
                    await this.add_status(status)
                }
            }
            this.loading = false
        }

    },
    async created() {
        await this.set_current_status(null);
        if (this._id) {
            await this.get_status_by_id(this._id)
            this.status = this.current_status.labelStatus
            this.color = this.current_status.color
        }
    },
    watch: {
        _id() {
            this.valid = false;
            this.status = "";
            this.color = "#000000";
            this.loading = false;
            this.loadingTask = false;
        }
    }
}
</script>

<style scoped>

</style>