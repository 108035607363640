<template>
    <v-app-bar :elevation="5" :class="show ? '' : 'tv'">
        <div class="d-flex w-100 align-center grid-gap-3 mx-3">
            <v-btn variant="outlined" to="/">Suivis des tâches</v-btn>
            <v-btn variant="outlined" to="/task" color="green">Ajouter une tâche</v-btn>
            <v-btn variant="outlined" to="/employee" color="green">Ajouter un employée</v-btn>
            <v-btn variant="outlined" to="/status" color="green">Ajouter un status</v-btn>
            <v-btn variant="outlined" to="/priority" color="green">Ajouter une priorité</v-btn>
            <v-btn variant="outlined" to="/edit" color="blue">Modifier</v-btn>
            <v-spacer/>
            <v-btn
                    icon="mdi-television"
                    to="/tv"
            ></v-btn>
        </div>
    </v-app-bar>
</template>


<script>
import router from "@/router";
import {mapState} from "vuex";

export default {
    name: "NavBar",
    computed: {
      ...mapState("dataStore", ["show"])
    },
    methods: {
        goTo(path) {
            router.push(path)
        }
    }
}
</script>

<style scoped>
.tv {
  position: absolute;
  opacity: 0;
  transition: opacity 1s;
}
.tv:hover {
  opacity: 1;
}
</style>