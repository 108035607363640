<template>
    <div>
        <v-select
                :base-color="status?.color"
                variant="outlined"
                :label="hideLabel ? '' : 'Status'"
                return-object
                :model-value="status"
                :items="all_status"
                item-title="labelStatus"
                :rules="[rules.ruleRequired]"
                @update:model-value="updateValue"
        ></v-select>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {mapState} from "vuex";

export default defineComponent({
    name: "StatusSelectComponent",
    props: {
        status: Object,
        hideLabel: Boolean
    },
    data: () => ({
        rules: {
            ruleRequired: v => !!v || 'Le champ est requis'
        }
    }),
    computed: {
        ...mapState("dataStore", ["all_status"])
    },
    methods: {
        updateValue(value) {
            this.$emit("update:model-value", value)
        },
    },
    emits: ["update:model-value"]
})

</script>


<style scoped>

</style>
<script setup>
</script>