<template>
  <div>
    <v-card :elevation="24" class="pa-3 translate">
      <v-data-table-virtual
          :loading="loading"
          :headers="headers"
          :items="all_task"
          :items-per-page="limit"
      >
        <template v-slot:item="{item}">
          <tr class="text-white" :style="`--background-color: ${item.value.priority?.color ? item.value.priority.color : '#757575'}`">
            <td>{{ item.value.tiers }}</td>
            <td>{{ item.value.motif }}</td>
            <td>
              <div class="d-flex flex-wrap mt-2">
              <span
                  v-for="(employee, index) in item.value.employees"
                  :key="index"
                  class="employee mb-2"
              >{{ employee.labelName }}</span>
              </div>
            </td>
            <td>
            <span v-if="item.value.startDateTask">
                {{ dateFormat.format(new Date(item.value.startDateTask)) }}
            </span>
            </td>
            <td>
            <span v-if="item.value.endDateTask">
                {{ dateFormat.format(new Date(item.value.endDateTask)) }}
            </span>
            </td>
            <td>{{ item.value.status?.labelStatus }}</td>
            <td>{{ item.value.priority?.labelPriority }}</td>
          </tr>
        </template>
      </v-data-table-virtual>
    </v-card>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions, mapMutations, mapState} from "vuex";

export default defineComponent({
  name: "DisplayView",
  data: () => ({
    intervalID: 0,
    limit: 100,
    loading: false,
    dateFormat: new Intl.DateTimeFormat('fr-FR', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    }),
    headers: [
      {title: 'Tiers', key: 'tiers'},
      {title: 'Motif', key: 'motif'},
      {title: 'Employés', key: 'employees'},
      {title: 'Début', key: 'startDateTask'},
      {title: 'Fin', key: 'endDateTask'},
      {title: 'Status', key: 'status'},
      {title: 'Priorité', key: 'priority'},
    ]
  }),
  computed: {
    ...mapState("dataStore", ["all_task"])
  },
  methods: {
    ...mapMutations("dataStore", ["set_show"]),
    ...mapActions("employeeStore", ["get_all_employee"]),
    ...mapActions("statusStore", ["get_all_status"]),
    ...mapActions("priorityStore", ["get_all_priority"]),
    ...mapActions("taskStore", ["get_all_task"]),
    async updateAllTask() {
      this.loading = true;
      await this.get_all_task({
        limit: this.limit
      });
      this.loading = false;
    },
    async getAllData() {
      await this.get_all_employee()
      await this.get_all_status()
      await this.get_all_priority()
      await this.updateAllTask()
    },
    async autoReload() {
      clearInterval(this.intervalID);
      await this.getAllData();
      this.intervalID = setInterval(this.getAllData, 1000 * 20);
    },
  },
  async created() {
    this.set_show(false);
    await this.autoReload();
  },
  beforeUnmount() {
    this.set_show(true);
    clearInterval(this.intervalID);
  }
})
</script>

<style scoped>
:root {
  --background-color: #757575;
}
.translate {
  translate: 0 -64px;
}
td {
  background-color: var(--background-color) !important;
  border: 1px solid #151515 !important;
  font-size: xx-large;
}
.employee {
  background-color: #FFFFFF;
  color: #000000;
  padding: 5px 10px;
  border-radius: 20px;
  margin: 0 3px;
}
</style>