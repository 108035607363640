<template>
    <div>
        <v-dialog
            v-model="dialogConfirm"
            :min-width="500"
            width="auto"
            transition="dialog-bottom-transition"
            class="not-select"
        >
            <v-card
                :title="`Êtes vous sûr de vousloir supprimer ${label} ?`"
                :loading="loadingAction"
                class="text-center"
            >
                <v-card-actions class="mx-auto">
                    <v-btn color="blue" variant="outlined" @click="closeDialog">
                        Annuler
                    </v-btn>
                    <v-btn color="red" variant="outlined" @click="delete_item">
                        Supprimer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn
            @click="dialogConfirm=true"
            color="red"
            text="Supprimer"
            block
            class="mt-3"
        ></v-btn>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "ConfirmComponent",
    props: {
        item: Object,
        label: String,
        function_action: Function
    },
    data: () => ({
        dialogConfirm: false,
        loadingAction: false
    }),
    methods: {
        closeDialog() {
            this.dialogConfirm = false;
        },
        async delete_item() {
            await this.function_action(this.item);
        }
    }
})
</script>

<style scoped>
.not-select {
    user-select: none;
}
</style>