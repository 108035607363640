import priorityService from "@/services/priority.service";
import router from "@/router";


export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async get_all_priority({commit}) {
            try {
                const res = await priorityService.get_all_priority()
                commit("dataStore/set_all_priority", res.data.data, {root: true})
            } catch (e) {
                commit("errorStore/setError", e, {root: true})
            }
        },
        async get_priority_by_id({commit}, _id) {
            try {
                const res = await priorityService.get_priority_by_id(_id)
                if (res.data.data === null) {
                    throw new Error("Cette priorité n'existe pas");
                }
                commit("dataStore/set_current_priority", res.data.data, {root: true})
            } catch (e) {
                await commit("errorStore/setError", e, {root: true})
                await router.push("/")
            }
        },
        async add_priority({commit}, priority) {
            try {
                await priorityService.add_priority(priority)
                await router.push("/")
            } catch (e) {
                commit("errorStore/setError", e, {root: true})
            }
        },
        async edit_priority({commit}, priority) {
            try {
                await priorityService.edit_priority(priority)
                await router.push("/")

            } catch (e) {
                commit("errorStore/setError", e, {root: true})
            }
        },
        async delete_priority({commit}, priority) {
            try {
                await priorityService.delete_priority(priority)
                await router.push("/")
            } catch (e) {
                commit("errorStore/setError", e, {root: true})
            }
        }
    }
}