<template>
    <v-app class="bg-grey-lighten-3">
        <ErrorComponent></ErrorComponent>
        <NavBar></NavBar>
        <v-main>
            <div class="ma-auto pa-4 px-lg-16" id="container">
                <router-view/>
            </div>
        </v-main>
    </v-app>
</template>

<script>
import ErrorComponent from "@/components/ErrorComponent.vue";
import NavBar from "@/components/NavBarComponent.vue";

export default {
    name: 'App',
    components: {NavBar, ErrorComponent},
    data: () => ({
        //
    })
}
</script>

<style>
.v-main, .d-grid {
    display: grid;
}

#container {
    width: 100vw;
}

.grid-gap-3 {
    grid-gap: 12px;
}
</style>