import statusService from "@/services/status.service";
import router from "@/router";


export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async get_all_status({commit}) {
            try {
                const res = await statusService.get_all_status()
                commit("dataStore/set_all_status", res.data.data, {root: true})
            } catch (e) {
                commit("errorStore/setError", e, {root: true})
            }
        },
        async get_status_by_id({commit}, _id) {
            try {
                const res = await statusService.get_status_by_id(_id)
                if (res.data.data === null) {
                    throw new Error("Ce status n'existe pas");
                }
                commit("dataStore/set_current_status", res.data.data, {root: true})
            } catch (e) {
                await commit("errorStore/setError", e, {root: true})
                await router.push("/")
            }
        },
        async add_status({commit}, status) {
            try {
                await statusService.add_status(status)
                await router.push("/")
            } catch (e) {
                commit("errorStore/setError", e, {root: true})
            }
        },
        async edit_status({commit}, status) {
            try {
                await statusService.edit_status(status)
                await router.push("/")
            } catch (e) {
                commit("errorStore/setError", e, {root: true})
            }
        },
        async delete_status({commit}, status) {
            try {
                await statusService.delete_status(status)
                await router.push("/")
            } catch (e) {
                commit("errorStore/setError", e, {root: true})
            }
        }
    }
}