<template>
    <div>
        <v-card class="elevation-24">
            <div class="d-flex align-center">
              <v-text-field
                  class="pa-3 w-100"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Recherche"
                  single-line
                  hide-details
                  clearable
                  persistent-clear
                  variant="outlined"
                  autofocus
              ></v-text-field>
              <v-checkbox-btn
                  v-model="isShowArchive"
                  class="mr-3"
                  true-icon="mdi-bullseye-arrow"
                  false-icon="mdi-bullseye"
                  :color="isShowArchive ? 'green' : 'grey'"
                  @click="updateSearch"
              ></v-checkbox-btn>
            </div>
            <div class=" d-flex px-3 grid-gap-3">
                <EmployeeSelectComponent
                    @input="updateSearch"
                    class="w-100"
                    @update:model-value="search=$event.labelName"
                    :employees="employees"
                ></EmployeeSelectComponent>
                <DateComponent
                    @input="updateSearch"
                    class="w-100"
                    @update:model-value="search=$event"
                    :date="date"
                ></DateComponent>
                <StatusSelectComponent
                    class="w-100"
                    @update:model-value="search=$event.labelStatus"
                    @input="updateSearch"
                    :status="status"
                ></StatusSelectComponent>
                <PrioritySelectComponent
                    class="w-100"
                    @update:model-value="search=$event.labelPriority"
                    @input="updateSearch"
                    :priority="priority"
                ></PrioritySelectComponent>
            </div>

            <v-data-table-server
                    @update:options="setPagination"
                    multi-sort
                    :loading="loading"
                    :headers="headers"
                    :items="all_task"
                    :items-length="all_task.length ? all_task[0].numberOfTask : 0"
                    :items-per-page="limit"
                    :page="page"
            >
                <template v-slot:[`item.employees`]="{item}">
                    <div>
                        <EmployeeSelectComponent
                                @update:model-value="updateEmployees(item.value, $event)"
                                :employees="item.value.employees"
                                :hideLabel="hideLabel"
                                is-multiple
                        ></EmployeeSelectComponent>
                    </div>
                </template>
                <template v-slot:[`item.startDateTask`]="{item}">
                    <div>
            <span v-if="item.value.startDateTask">
                {{ dateFormat.format(new Date(item.value.startDateTask)) }}
            </span>
                        <v-alert
                                v-else
                                text="Pas de date de début"
                                type="error"
                        ></v-alert>
                    </div>
                </template>
                <template v-slot:[`item.endDateTask`]="{item}">
                    <div>
            <span v-if="item.value.endDateTask">
                {{ dateFormat.format(new Date(item.value.endDateTask)) }}
            </span>
                        <v-btn
                                v-else
                                :to="`/task/${item.value._id}`"
                                text="Pas de date de fin"
                                append-icon="mdi-pencil"
                        ></v-btn>
                    </div>
                </template>
                <template v-slot:[`item.status`]="{item}">
                    <div>
                        <StatusSelectComponent
                                @update:model-value="updateStatus(item.value, $event)"
                                :status="item.value.status"
                                :hideLabel="hideLabel"
                        ></StatusSelectComponent>
                    </div>
                </template>
                <template v-slot:[`item.priority`]="{item}">
                    <div>
                        <PrioritySelectComponent
                                @update:model-value="updatePriority(item.value, $event)"
                                :priority="item.value.priority"
                                :hideLabel="hideLabel"
                        ></PrioritySelectComponent>
                    </div>
                </template>
                <template v-slot:[`item.edit`]="{item}">
                    <v-btn
                            icon="mdi-pencil"
                            color="info"
                            variant="tonal"
                            :to="`/task/${item.value._id}`"
                    ></v-btn>
                </template>

                <template v-slot:no-data>
                    <v-alert
                            text="Aucune tâche pour votre recherche"
                            color="error"
                    ></v-alert>
                </template>
            </v-data-table-server>
        </v-card>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions, mapState} from "vuex";
import PrioritySelectComponent from "@/components/SelectComponent/PrioritySelectComponent.vue";
import EmployeeSelectComponent from "@/components/SelectComponent/EmployeeSelectComponent.vue";
import StatusSelectComponent from "@/components/SelectComponent/StatusSelectComponent.vue";
import DateComponent from "@/components/DateComponent.vue";

export default defineComponent({
    name: "TableOfTaskView",
    components: {DateComponent, StatusSelectComponent, PrioritySelectComponent, EmployeeSelectComponent},
    data: () => ({
        hideLabel: true,
        loading: false,
        intervalID: 0,
        limit: 25,
        page: 1,
        sort_by: [],
        isShowArchive: false,
        search: "",
        employees: [],
        date: "",
        status: null,
        priority: null,
        dateFormat: new Intl.DateTimeFormat('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        }),
        headers: [
            {title: 'Tiers', key: 'tiers', sortable: true},
            {title: 'Motif', key: 'motif', sortable: true},
            {title: 'Employés', key: 'employees', sortable: false},
            {title: 'Date de début', key: 'startDateTask', sortable: true},
            {title: 'Date de fin', key: 'endDateTask', sortable: true},
            {title: 'Status', key: 'status', sortable: true},
            {title: 'Priorité', key: 'priority', sortable: true},
            {title: 'Modifier', key: 'edit', sortable: false},
        ],
    }),
    computed: {
        ...mapState("dataStore", ["all_task"])
    },
    methods: {
        ...mapActions("employeeStore", ["get_all_employee"]),
        ...mapActions("statusStore", ["get_all_status"]),
        ...mapActions("priorityStore", ["get_all_priority"]),
        ...mapActions("taskStore", ["get_all_task", "edit_task"]),
        setPagination({page, itemsPerPage, sortBy}) {
            if (itemsPerPage === -1) {
                itemsPerPage = this.all_task[0].numberOfTask;
            }
            this.page = page;
            this.limit = itemsPerPage;
            this.sort_by = sortBy
            this.getAllData();
        },
        updateSearch() {
            this.limit = 25;
            this.page = 1;
            this.getAllData()
        },
        async updateAllTask() {
            await this.get_all_task({
                limit: this.limit,
                page: this.page,
                sort_by: this.sort_by,
                search: this.search,
                isShowArchive: this.isShowArchive
            });
        },
        async getAllData() {
            this.loading = true;
            await this.get_all_employee()
            await this.get_all_status()
            await this.get_all_priority()
            await this.updateAllTask()
            this.loading = false;
        },
        async autoReload() {
            clearInterval(this.intervalID);
            await this.getAllData();
            this.intervalID = setInterval(this.getAllData, 1000 * 60);
        },
        async updateEmployees(task, employees) {
            task.employees = employees;
            await this.edit_task(task);
        },
        async updateStatus(task, status) {
            task.status = status;
            await this.edit_task(task);

        },
        async updatePriority(task, priority) {
            task.priority = priority;
            await this.edit_task(task);
        },
    },
    async created() {
        await this.autoReload();
    },
    beforeUnmount() {
        clearInterval(this.intervalID);
    },
    watch: {
        search () {
            this.updateSearch()
        }
    }
})
</script>

<style scoped>

</style>