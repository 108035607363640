<template>
    <div>
        <v-select
                chips
                variant="outlined"
                :label="hideLabel ? '' : 'Destinataire'"
                :model-value="employees"
                @update:model-value="updateValue"
                return-object
                :items="all_employee"
                item-title="labelName"
                :multiple="isMultiple"
                :rules="[rules.ruleRequired]"
        >
            <template v-slot:prepend-item v-if="isMultiple">
                <v-list-item
                    :title="employees.length ? 'Tout deséléctionné' : 'Tout séléctionné'"
                    @click="toggle"
                >
                    <template v-slot:prepend>
                        <v-checkbox-btn
                            :indeterminate="employees.length"
                            :model-value="employees.length"
                        ></v-checkbox-btn>
                    </template>
                </v-list-item>

                <v-divider class="mt-2"></v-divider>
            </template>
        </v-select>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions, mapState} from "vuex";

export default defineComponent({
    name: "EmployeeSelectComponent",
    props: {
        employees: Array,
        hideLabel: Boolean,
        isMultiple: Boolean
    },
    data: () => ({
        rules: {
            ruleRequired: value => !!value.length || 'Le champ est requis',
        }
    }),
    computed: {
        ...mapState("dataStore", ["all_employee"])
    },
    methods: {
        ...mapActions("taskStore", ["edit_task"]),
        updateValue(value) {
            this.$emit('update:model-value', value);
        },
        toggle () {
            if (this.employees.length) {
                this.updateValue([]);
            } else {
                this.updateValue(this.all_employee);
            }
        },
    },
    emits: ["update:model-value"],
})
</script>

<style scoped>

</style>