import myAxios from "@/services/axios.service";

export default {
    get_all_priority() {
        return myAxios.get("/get_all_priority");
    },
    get_priority_by_id(_id) {
        return myAxios.get("/get_priority_by_id/" + _id);
    },
    add_priority(priority) {
        return myAxios.post("/add_priority", priority);
    },
    edit_priority(priority) {
        return myAxios.put("/edit_priority", priority);
    },
    delete_priority(priority) {
        return myAxios.delete("/delete_priority/" + priority._id);
    }
}