<template>
    <v-card class="elevation-24 pa-16 d-flex grid-gap">
        <v-select
                v-model="employee"
                :items="all_employee"
                item-title="labelName"
                variant="outlined"
                label="Destinataire"
                return-object
        >
            <template v-slot:item="{item}">
                <div class="pa-1 px-5">
                    <v-btn
                        variant="outlined"
                        :text="item.value.labelName"
                        :to="`/employee/${item.value._id}`"
                        append-icon="mdi-pencil"
                        block
                    ></v-btn>
                </div>
            </template>
        </v-select>
        <v-select
                v-model="status"
                :items="all_status"
                item-title="labelStatus"
                variant="outlined"
                label="Status"
                return-object
        >
            <template v-slot:item="{item}">
                <div class="pa-1 px-5">
                    <v-btn
                        :style="`color: ${item.value.color}`"
                        variant="outlined"
                        :text="item.value.labelStatus"
                        :to="`/status/${item.value._id}`"
                        append-icon="mdi-pencil"
                        block
                    ></v-btn>
                </div>
            </template>
        </v-select>
        <v-select
                v-model="priority"
                :items="all_priority"
                item-title="labelPriority"
                variant="outlined"
                label="Priorité"
                return-object
        >
            <template v-slot:item="{item}">
                <div class="pa-1 px-5">
                    <v-btn
                        :style="`color: ${item.value.color}`"
                        variant="outlined"
                        :text="item.value.labelPriority"
                        :to="`/priority/${item.value._id}`"
                        append-icon="mdi-pencil"
                        block
                    ></v-btn>
                </div>
            </template>
        </v-select>
    </v-card>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions, mapState} from "vuex";

export default defineComponent({
    name: "EditView",
    data: () => ({
        employee: null,
        status: null,
        priority: null
    }),
    computed: {
        ...mapState("dataStore", ["all_employee", "all_status", "all_priority"])
    },
    methods: {
        ...mapActions("employeeStore", ["get_all_employee"]),
        ...mapActions("statusStore", ["get_all_status"]),
        ...mapActions("priorityStore", ["get_all_priority"]),
        async getAllData() {
            await this.get_all_employee()
            await this.get_all_status()
            await this.get_all_priority()
        },
    },
    async created() {
        await this.getAllData();
    },
})
</script>

<style scoped>
.grid-gap {
    grid-gap: 20px;
}
</style>