<template>
    <v-card
            class="pa-6"
            elevation="24">
        <v-skeleton-loader
                v-if="loadingTask"
                type="paragraph, paragraph, paragraph, paragraph"
        ></v-skeleton-loader>
        <v-form
                v-else
                @submit.prevent="submit"
                v-model.lazy="valid"
                ref="form"
        >
            <v-text-field
                    clearable
                    label="Tier(s)"
                    v-model="tiers"
                    :rules="[rules.ruleRequired]"
                    autofocus
            ></v-text-field>
            <v-textarea
                    label="Motif"
                    v-model="motif"
                    clearable
                    :rules="[rules.ruleRequired]"
            ></v-textarea>

            <EmployeeSelectComponent
                    :employees="employees"
                    @update:model-value="employees = $event"
                    is-multiple
            ></EmployeeSelectComponent>


            <v-text-field
                    type="date"
                    v-model="endDateTask"
                    clearable
                    label="Date de fin"
            ></v-text-field>

            <StatusSelectComponent @update:model-value="status=$event" :status="status"></StatusSelectComponent>

            <PrioritySelectComponent @update:model-value="priority=$event"
                                     :priority="priority"></PrioritySelectComponent>

            <v-btn
                    color="success"
                    class="mt-4"
                    block
                    type="submit"
                    :loading="loading"

            >
                {{ _id ? "Editer" : "Ajouter" }}
            </v-btn>
            <ConfirmComponent
                    v-if="_id"
                    :item="current_task"
                    :label="current_task?.motif"
                    :function_action="delete_task"
            ></ConfirmComponent>
        </v-form>
    </v-card>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions, mapMutations, mapState} from "vuex";
import ConfirmComponent from "@/components/ConfirmComponent.vue";
import EmployeeSelectComponent from "@/components/SelectComponent/EmployeeSelectComponent.vue";
import StatusSelectComponent from "@/components/SelectComponent/StatusSelectComponent.vue";
import PrioritySelectComponent from "@/components/SelectComponent/PrioritySelectComponent.vue";

export default defineComponent({
    name: "TaskComponent",
    components: {EmployeeSelectComponent, StatusSelectComponent, PrioritySelectComponent, ConfirmComponent},
    props: {
        _id: String
    },
    data: () => ({
        tiers: "",
        endDateTask: "",
        status: "",
        priority: "",
        valid: false,
        loading: false,
        loadingTask: false,
        motif: "",
        employees: [],
        rules: {
            ruleRequired: value => !!value || 'Le champ est requis',
        },
        dateFormat: new Intl.DateTimeFormat('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        }),
    }),
    computed: {
        ...mapState("dataStore", ["all_status", "all_priority", "current_task"]),
        ...mapState("errorStore", ["isError"])
    },
    methods: {
        ...mapActions("taskStore", ["add_task", "get_task_by_id", "edit_task", "delete_task"]),
        ...mapActions("employeeStore", ["get_all_employee"]),
        ...mapActions("statusStore", ["get_all_status"]),
        ...mapActions("priorityStore", ["get_all_priority"]),
        ...mapMutations("dataStore", ["set_current_task"]),
        async submit() {
            if ((await this.$refs.form.validate()).valid) {
                this.loading = true
                let task = {
                    tiers: this.tiers,
                    motif: this.motif,
                    employees: this.employees,
                    endDateTask: this.endDateTask,
                    status: this.status,
                    priority: this.priority
                }
                if (this._id) {
                    task._id = this._id
                    await this.edit_task(task)
                } else {
                    await this.add_task(task)
                }
            }
            this.loading = false
        },
        async getAllData() {
            this.loadingTask = true
            await this.get_all_employee()
            await this.get_all_status()
            await this.get_all_priority()
        },
        async getDataByID() {
            if (this._id) {
                await this.get_task_by_id(this._id)
                this.tiers = this.current_task.tiers
                this.motif = this.current_task.motif
                this.employees = this.current_task.employees
                this.endDateTask = this.current_task.endDateTask
                if (this.endDateTask) {
                    this.endDateTask = this.endDateTask.substring(0, 10)
                }
                this.status = this.current_task.status
                this.priority = this.current_task.priority
            }
        }
    },
    async created() {
        await this.set_current_task(null);
        await this.getAllData();
        await this.getDataByID();
        if (!this._id) {
            this.status = this.all_status.find(status => status.labelStatus === "À faire")
            this.priority = this.all_priority.find(status => status.labelPriority === "Moyen")
        }
        this.loadingTask = false;
    },
    watch: {
        _id() {
            this.tiers = "";
            this.endDateTask = "";
            this.status = "";
            this.priority = "";
            this.valid = false;
            this.loading = false;
            this.loadingTask = false;
            this.motif = "";
            this.employees = [];
        }
    }
})
</script>

<style scoped>

</style>