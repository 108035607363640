import myAxios from "@/services/axios.service";

export default {
    get_all_status() {
        return myAxios.get("/get_all_status");
    },
    get_status_by_id(_id) {
        return myAxios.get("/get_status_by_id/" + _id);
    },
    add_status(status) {
        return myAxios.post("/add_status", status);
    },
    edit_status(status) {
        return myAxios.put("/edit_status", status);
    },
    delete_status(status) {
        return myAxios.delete("/delete_status/" + status._id);
    }
}