export default {
    namespaced: true,
    state: {
        isError: false,
        textError: "Veuillez attendre le chargement des données."
    },
    getters: {},
    mutations: {
        setError: (state, textError) => {
            state.isError = true
            state.textError = textError.toString()
            console.error(textError);
        },
        popError(state) {
            state.textError=''
            state.isError = false;
        }
    },
    actions: {}
}