import { createRouter, createWebHistory } from 'vue-router'
import TableOfTaskView from "@/views/TableOfTaskView.vue";
import DisplayView from "@/views/DisplayView.vue";
import EditView from "@/views/EditView.vue";
import TaskComponent from "@/components/TaskComponent.vue";
import StatusComponent from "@/components/StatusComponent.vue";
import PriorityComponent from "@/components/PriorityComponent.vue";
import EmployeeComponent from "@/components/EmployeeComponent.vue";

const routes = [
  {
    path: '/',
    name: 'TableOfTaskView',
    component: TableOfTaskView
  },
  {
    path: '/tv',
    name: 'DisplayView',
    component: DisplayView
  },
  {
    path: '/edit',
    name: 'EditView',
    component: EditView
  },
  {
    path: "/task",
    name: "AddTaskComponent",
    component: TaskComponent
  },
  {
    path: "/task/:_id",
    name: "EditTaskComponent",
    component: TaskComponent,
    props: (route) => ({
      _id: route.params._id
    })
  },
  {
    path: "/status",
    name: "AddStatusComponent",
    component: StatusComponent,
  },
  {
    path: "/status/:_id",
    name: "EditStatusComponent",
    component: StatusComponent,
    props: (route) => ({
      _id: route.params._id
    })
  },
  {
    path: "/employee",
    name: "AddEmployéeComponent",
    component: EmployeeComponent,
  },
  {
    path: "/employee/:_id",
    name: "EditEmployéeComponent",
    component: EmployeeComponent,
    props: (route) => ({
      _id: route.params._id
    })
  },
  {
    path: "/priority",
    name: "AddPriorityComponent",
    component: PriorityComponent,
  },
  {
    path: "/priority/:_id",
    name: "EditPriorityComponent",
    component: PriorityComponent,
    props: (route) => ({
      _id: route.params._id
    })
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
