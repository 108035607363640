<template>
  <div>
    <v-text-field
        variant="outlined"
        type="date"
        :model-value="date"
        @update:model-value="updateValue"
    ></v-text-field>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "DateComponent",
  props: {
    date: String
  },
  methods: {
    updateValue(value) {
      this.$emit("update:model-value", value)
    }
  }
})
</script>

<style scoped>

</style>