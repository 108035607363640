<template>
    <div>
        <v-select
                :base-color="priority?.color"
                variant="outlined"
                :label="hideLabel ? '' : 'Priorité'"
                return-object
                :model-value="priority"
                :items="all_priority"
                item-title="labelPriority"
                :rules="[rules.ruleRequired]"
                @update:model-value="updateValue"
        ></v-select>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {mapState} from "vuex";

export default defineComponent({
    name: "PrioritySelectComponent",
    props: {
        priority: Object,
        hideLabel: Boolean
    },
    data: () => ({
        rules: {
            ruleRequired: v => !!v || 'Le champ est requis'
        }
    }),
    computed: {
        ...mapState("dataStore", ["all_priority"])
    },
    methods: {
        updateValue(value) {
            this.$emit("update:model-value", value)
        }
    },
    emits: ["update:model-value"]
})
</script>


<style scoped>

</style>