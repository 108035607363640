import myAxios from "@/services/axios.service";

export default {
    get_all_task(filter) {
        filter = {
            limit: filter.limit ? filter.limit : 25,
            page: filter.page ? filter.page - 1 : 0,
            sort_by: filter.sort_by ? JSON.stringify(filter.sort_by) : [],
            search: filter.search,
            isShowArchive: filter.isShowArchive
        }
        filter.offset = filter.limit * filter.page;
        return myAxios.get("/get_all_task", {
            params: filter
        });
    },
    get_task_by_id(_id) {
        return myAxios.get("/get_task_by_id/" + _id);
    },
    add_task(task) {
        return myAxios.post("/add_task", task);
    },
    edit_task(task) {
        return myAxios.put("/edit_task", task);
    },
    delete_task(task) {
        return myAxios.delete("/delete_task/" + task._id);
    }
}